import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import injectSheet from 'react-jss';
import { Select, SelectOption, Theme as theme, InfoStylizedIcon } from '@spoiler-alert/ui-library';

const styles = {
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  siteSelectWrap: {
    borderRadius: '4px',
    border: `1px ${theme.grey30} solid`,
    marginRight: 8,
    width: 260,
    paddingRight: 8,
    boxSizing: 'content-box',
  },
  siteSelectOption: {
    paddingRight: '10px !important',
  },
  siteListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  tableSelectWrap: {
    borderRadius: '4px',
    border: `1px ${theme.grey30} solid`,
    marginRight: 8,
    width: 350,
    paddingRight: 8,
    boxSizing: 'content-box',
  },
  tableSelectOption: {
    paddingRight: '10px !important',
  },
  tableListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
  },
  info: {
    margin: '300px 400px',
    width: '50%',
  },
  infoBox: {
    width: '396px',
    height: 52,
    flexGrow: 0,
    marginTop: -20,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 17,
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 1,
    paddingLeft: 1,
    borderRadius: 6,
    border: 'solid 1px #f5e174',
    backgroundColor: '#fefcee',
  },
  infoText: {
    width: '394px',
    height: 20,
    flexGrow: 0,
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.43',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#333',
  },
};

const DataTableManagerNavigation = ({ classes, sites, site, setSite, tableTypes, tableType, setTableType }) => {
  const currentSite = sites && sites.find((s) => s._id === site._id);
  const currentTableType = tableTypes && tableTypes.find((t) => t === tableType);
  const [siteSelection, setSiteSelection] = useState(null);
  const [tableTypeSelection, setTableTypeSelection] = useState(null);

  const changeSite = (option) => {
    setSiteSelection(option);
    const selectedSite = sites.find((s) => s._id === option[0].value);
    setSite({ _id: selectedSite._id, siteName: selectedSite.siteName });
  };

  const changeTableType = (option) => {
    setTableTypeSelection(option);
    setTableType(option[0].value);
  };

  return (
    <div className={classes.controls}>
      <div className={classes.siteSelectWrap}>
        <Select minimal search placeholderText="Customer" onChange={changeSite} selectedItems={siteSelection}>
          {sites &&
            sites.map((s) => (
              <SelectOption key={s._id} value={s._id} searchText={s.siteName} className={classes.siteSelectOption}>
                <div className={classes.siteListItem}>{s.siteName}</div>
              </SelectOption>
            ))}
        </Select>
      </div>
      <div className={classes.tableSelectWrap}>
        <Select minimal search placeholderText="Table" onChange={changeTableType} selectedItems={tableTypeSelection} disabled={!currentSite}>
          {tableTypes &&
            tableTypes.map((t) => (
              <SelectOption key={t} value={t} searchText={t} className={classes.tableSelectOption}>
                <div className={classes.tableListItem}>{t}</div>
              </SelectOption>
            ))}
        </Select>
      </div>
      {!currentTableType && (
        <div className={classes.info}>
          <InfoStylizedIcon />
          <div className={classes.infoBox}>
            <div className={classes.infoText}>Select a Customer and Table to get started.</div>
          </div>
        </div>
      )}
    </div>
  );
};

DataTableManagerNavigation.propTypes = {
  classes: PropTypes.object,
  sites: PropTypes.array,
  site: PropTypes.object,
  setSite: PropTypes.func,
  tableTypes: PropTypes.array,
  tableType: PropTypes.string,
  setTableType: PropTypes.func,
};

export default injectSheet(styles)(DataTableManagerNavigation);
