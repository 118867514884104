import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TextInput, TextArea, LoadingLarge, Button, NoImageIcon, AlertService, Radio, Checkbox, EmailCard, Theme } from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import marketplaceStyles from './marketplace-styles';
import { saveMarketplaceSKU } from './marketplace-mutations';
import HTMLEditor from '../../components/editor/html-editor';

const useStyles = createUseStyles(marketplaceStyles);

const truckTypeOptions = [
  { text: 'Dry', value: 'Dry' },
  { text: 'Refrigerated', value: 'Refrigerated' },
  { text: 'Frozen', value: 'Frozen' },
];

const SKUDetails = ({ bread, skus, loadingSKUs, facilityIds, match, history, tagList }) => {
  const classes = useStyles();
  const dbSKU = skus?.find((s) => s._id === match.params.sku);

  const [sku, setSKU] = useState(null);
  const [changedFields, setChangedFields] = useState([]);
  const [currentTag, setCurrentTag] = useState('');

  const [save] = useMutation(saveMarketplaceSKU);

  const breadcrumbs = useMemo(
    () => [
      ...bread,
      {
        url: history.location.pathname,
        title: `Editing ${dbSKU?.description}`,
      },
    ],
    [dbSKU]
  );

  useEffect(() => {
    TitleService.setTitles(`Marketplace Manager => Editing ${dbSKU?.description}`);
    Breadcrumbs.set(breadcrumbs);
  }, [breadcrumbs]);

  useEffect(() => {
    if (dbSKU) {
      setSKU({ ...dbSKU });
    }
  }, [dbSKU]);

  const updateValue = (field, value) => {
    const _sku = { ...sku };
    _sku[field] = value;
    setChangedFields([...new Set([...changedFields, field]).values()]);
    setSKU(_sku);
  };

  const updateArrayValue = (field, delimiter, value) => {
    updateValue(
      field,
      value.split(delimiter).map((v) => v.trim())
    );
  };

  const updateRadioValue = (field, option) => {
    updateValue(field, option.value);
  };

  const updateCheckboxValue = (field, value) => {
    const _sku = { ...sku };
    _sku[field] = value.checked;
    setChangedFields([...new Set([...changedFields, field]).values()]);
    setSKU(_sku);
  };

  const addTag = (tag) => {
    const existingTags = sku.tags || [];
    const tags = [...new Set([...existingTags, tag])];
    updateValue('tags', tags);
  };

  const submitTag = (ev) => {
    ev.preventDefault();
    addTag(currentTag);
    setCurrentTag('');
  };

  const editExistingTag = (tag) => {
    const tags = [...sku.tags].filter((t) => t !== tag);
    updateValue('tags', tags);
    setCurrentTag(tag);
  };

  const selectedTruckType = truckTypeOptions.find((tt) => tt.value === sku?.truckType);

  const saveSKU = () => {
    const variables = {
      _id: dbSKU._id,
      facilityIds,
      fields: {
        isPackout: sku.isPackout,
      },
    };
    changedFields.forEach((f) => {
      variables.fields[f] = sku[f];
    });
    save({
      variables,
    })
      .then(() => history.push(`${routePaths.marketplaceManager}/${match.params.storefrontId}/skus`))
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
    const { tags } = variables.fields;
    if (tags?.length > 0) {
      const userTags = localStorage.getItem('userTags')?.split(',') || [];
      const newUserTags = [...new Set([...userTags, ...tags])];
      localStorage.setItem('userTags', newUserTags.join(','));
    }
  };

  const matches = useMemo(() => {
    if (currentTag === '') return null;
    const tagsRegex = new RegExp(/^(\W{1,2}) ([A-z0-9 !.?-]*)(\/?(.*))$/);
    const r = currentTag.match(tagsRegex);
    if (!r) return { emoji: '{none}', flyout: '{none}', tooltip: '{none}' };
    return {
      emoji: r[1] && r[1].trim() !== '' && r[4] ? r[1] : '{none}',
      flyout: r[1] && r[2] ? `${r[1]} ${r[2]}` : '{none}',
      tooltip: r[4] || '{none}',
    };
  }, [currentTag]);

  if (loadingSKUs || !sku) return <LoadingLarge />;
  return (
    <div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'description')}
          value={sku.description || ''}
          type="text"
          labelText="Short Description"
          required
        />
      </div>
      <div className={classes.textAreaBox}>
        <div className={classes.fieldLabel}>Long Description</div>
        <HTMLEditor defaultValue={sku?.longDescription} onChange={updateValue.bind(this, 'longDescription')} />
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'itemName')}
          value={sku.itemName || ''}
          type="text"
          labelText="Item Name"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'casesPerPallet')}
          value={sku.casesPerPallet || ''}
          type="number"
          labelText="Cases Per Pallet"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <div className={classes.fieldLabel}>Truck Type</div>
        <Radio label="Truck Type" options={truckTypeOptions} selectedItem={selectedTruckType} onChange={updateRadioValue.bind(this, 'truckType')} />
      </div>
      <div className={classes.imageBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'thumbnailImage')}
          value={sku.thumbnailImage || ''}
          type="text"
          labelText="Thumbnail URL"
          required
        />
        {sku.thumbnailImage ? <img src={sku.thumbnailImage} /> : <NoImageIcon className={classes.noImage} />}
      </div>
      <div className={classes.imageBox}>
        <TextArea
          className={classes.textAreaField}
          onChange={updateArrayValue.bind(this, 'galleryImages', '\n')}
          value={sku.galleryImages.join('\n')}
          type="text"
          labelText="Gallery Image URLs"
          required
        />
        {sku.galleryImages.map((i, index) => (
          <span key={index}>{i !== null ? <img src={i} /> : <NoImageIcon className={classes.noImage} />}</span>
        ))}
      </div>{' '}
      <div className={classes.fieldBox}>
        <div className={classes.fieldLabel}>Is Packout?</div>
        <Checkbox className={classes.textField} onChecked={updateCheckboxValue.bind(this, 'isPackout')} checked={sku.isPackout} required />
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'leadTime')}
          value={sku.leadTime || ''}
          type="number"
          labelText="Lead Time"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <div className={classes.fieldLabel}>Tags for this inventory</div>
        <div className={classes.tagBox}>
          {sku.tags?.map((tag) => (
            <EmailCard key={tag} email={tag} backgroundColor={Theme.teal5} borderColor={Theme.tealDark} handleEdit={(t) => editExistingTag(t)} />
          ))}
        </div>
        <form onSubmit={submitTag} className={classes.tagForm}>
          <TextInput
            placeholder="🔥 Flyout Text!/Tooltip Text"
            className={classes.tagField}
            onChange={(value) => setCurrentTag(value)}
            value={currentTag}
            type="text"
          />
          <Button submit>Add Tag</Button>
          {matches && (
            <table>
              <thead>
                <tr>
                  <th>Thumbnail Emoji</th>
                  <th>Tooltip</th>
                  <th>Flyout</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{matches.emoji}</td>
                  <td>{matches.tooltip}</td>
                  <td>{matches.flyout}</td>
                </tr>
              </tbody>
            </table>
          )}
        </form>
        <div className={classes.fieldLabel}>Previously used tags, click to add...</div>
        <div className={classes.extraTagList}>
          {tagList?.map((tag) => (
            <EmailCard key={tag} email={tag} backgroundColor={Theme.green30} borderColor={Theme.greenDark} handleEdit={(t) => addTag(t)} />
          ))}
        </div>
      </div>
      <div className={classes.submitBox}>
        <Button primary onClick={saveSKU}>
          Save SKU
        </Button>
        <Button secondary onClick={() => history.push(`${routePaths.marketplaceManager}/${match.params.storefrontId}/skus`)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

SKUDetails.propTypes = {
  bread: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  loadingSKUs: PropTypes.bool,
  skus: PropTypes.array,
  facilityIds: PropTypes.array,
  tagList: PropTypes.arrayOf(PropTypes.string),
};

export default SKUDetails;
