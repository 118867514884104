import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import {
  Column,
  DataTableNaked,
  Theme,
  Button,
  Select,
  SelectOption,
  RowAction,
  CircleCheckIcon,
  PencilIcon,
  WasteIcon,
  LoadingLine,
  Modal,
  ModalContent,
  ModalFooter,
  TextInput,
  AlertService,
} from '@spoiler-alert/ui-library';
import { TitleService } from '../services';
import { Breadcrumbs } from '../store';
import { AdminDashboardListQuery, AdminDashboardRenderQuery } from '../graphql/queries';
import { DeleteHolisticsDashboardMutation, SaveHolisticsDashboardMutation } from '../graphql/mutations';

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px solid ${Theme.tableBorderColor}`,
  },
  controlBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  siteSelect: {
    width: 300,
  },
  modalContent: {
    width: 652,
  },
  hint: {
    fontSize: '0.6rem',
    marginTop: -5,
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const { data, loading: loadingDashboards } = useQuery(AdminDashboardListQuery);

  const [siteSelection, setSiteSelection] = useState(null);
  const [viewing, setViewing] = useState(null);
  const [editingDashboard, setEditingDashboard] = useState(null);

  const dashboards = data?.adminDashboardListQuery?.dashboards || [];

  const { data: embedResult, loading: loadingEmbed } = useQuery(AdminDashboardRenderQuery, {
    variables: {
      dashboardIds: dashboards.map((d) => d._id),
      sellerSiteId: siteSelection?.length > 0 ? siteSelection[0].value : null,
    },
    skip: !dashboards.length || !siteSelection,
  });

  const [saveDashboardToDb, { loading: creating }] = useMutation(SaveHolisticsDashboardMutation);
  const [deleteDashboardFromDb, { loading: deleting }] = useMutation(DeleteHolisticsDashboardMutation);

  const loading = loadingDashboards || loadingEmbed || creating || deleting;

  const saveDashboard = () => {
    const variables = {
      dashboardType: editingDashboard.dashboardType[0].value,
      defaultForLocation: editingDashboard.defaultForLocation?.length > 0 ? editingDashboard.defaultForLocation[0].value : undefined,
      sellerSiteIds: editingDashboard.sellerSiteIds ? editingDashboard.sellerSiteIds.map((ss) => ss.value) : [],
    };
    if (editingDashboard._id) {
      variables._id = editingDashboard._id;
      variables.name = editingDashboard.name;
    } else {
      variables.dashboardId = parseFloat(editingDashboard.dashboardId);
    }
    saveDashboardToDb({ variables, refetchQueries: ['adminDashboardListQuery'] })
      .then((response) => {
        if (response.data.saveHolisticsDashboard.errors?.length > 0) {
          throw new Error(response.data.saveHolisticsDashboard.errors[0].message);
        }
        setEditingDashboard(null);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };

  useEffect(() => {
    TitleService.setTitles('Administration Panel');
    Breadcrumbs.set([
      {
        url: '/',
        title: 'Holistics Dashboards',
      },
    ]);
  }, []);

  const filteredDashboards = useMemo(() => {
    if (siteSelection?.length > 0) {
      const siteId = siteSelection[0].value;
      return dashboards.filter((dash) => dash.type === 'CORE' || dash.sellerSiteIds.includes(siteId));
    }
    return [...dashboards];
  }, [dashboards, siteSelection]);

  const sites = data?.adminDashboardListQuery?.sellerOrgs || [];

  const sitesMap = useMemo(() => {
    return sites.reduce((map, site) => {
      map.set(site._id, site);
      return map;
    }, new Map());
  }, [sites]);

  const columns = useMemo(() => {
    return [
      new Column({ field: 'name', displayName: 'Name', sortable: true }),
      new Column({ field: 'reportId', displayName: 'Report ID', sortable: true }),
      new Column({
        field: 'type',
        displayName: 'Type',
        sortable: true,
      }),
      new Column({
        field: 'defaultForLocation',
        displayName: 'Location Default?',
        sortable: true,
      }),
      new Column({
        field: 'sellerSiteIds',
        displayName: 'Seller Sites',
        sortable: false,
        formatter: (values) => <span>{values.map((v) => sitesMap.get(v).siteName).join(', ')}</span>,
      }),
      new Column({
        field: 'orgFilterPaths',
        displayName: 'Permissions Paths',
        formatter: (values) => (
          <div>
            {values.map((v) => (
              <div key={v}>{v}</div>
            ))}
          </div>
        ),
      }),
      new Column({
        field: 'updatedAt',
        displayName: 'Last Changed',
        formatter: (value) => moment(value).format('MM/DD/YYYY'),
        defaultSort: true,
        sortable: true,
      }),
    ];
  }, [sitesMap]);

  const handleChangeSite = (option) => {
    setSiteSelection(option);
  };

  const handleViewAs = (row) => setViewing(row._id);

  const iframeUrl = useMemo(() => {
    if (viewing && embedResult) {
      const params = embedResult.adminDashboardRenderQuery.find((r) => r._id === viewing);
      return `https://us.holistics.io/embed/${params.embedCode}?_token=${params.token}`;
    }
    return '';
  }, [viewing, siteSelection, embedResult]);

  const handleHideModal = () => setEditingDashboard(null);

  const updateValue = (field, value) => {
    const _dashboard = { ...editingDashboard };
    _dashboard[field] = value;
    setEditingDashboard(_dashboard);
  };

  const siteSelectOptions = useMemo(() => {
    if (sites) {
      return sites.map((s) => (
        <SelectOption key={s._id} value={s._id} searchText={s.siteName}>
          {s.siteName}
        </SelectOption>
      ));
    }
    return [];
  }, [sites]);

  const handleEdit = (row) => {
    const { type, sellerSiteIds, defaultForLocation, name } = row;
    const dashboard = {
      _id: row._id,
      name,
      dashboardType: [{ text: type, value: type }],
      defaultForLocation: defaultForLocation ? [{ text: defaultForLocation, value: defaultForLocation }] : [{ text: 'None', value: '' }],
      sellerSiteIds: sellerSiteIds.map((ssid) => ({ text: sitesMap.get(ssid).siteName, value: ssid })),
    };
    setEditingDashboard(dashboard);
  };

  const handleNew = () => {
    setEditingDashboard({
      dashboardId: '',
      dashboardType: [],
      defaultForLocation: [{ text: 'None', value: '' }],
      sellerSiteIds: [],
    });
  };

  const handleDelete = async (row) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete the Dashboard ${row.name}?`)) {
      await deleteDashboardFromDb({
        variables: {
          _id: row._id,
        },
        refetchQueries: ['adminDashboardListQuery'],
      });
    }
  };

  return (
    <div>
      <div className={classes.controlBar}>
        <div className={classes.siteSelect}>
          <Select search label="Preview & Filter for..." onChange={handleChangeSite} selectedItems={siteSelection}>
            {siteSelectOptions}
          </Select>
        </div>
        <div>
          <Button onClick={handleNew}>Create New</Button>
        </div>
      </div>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={filteredDashboards}
          columns={columns}
          loading={loadingDashboards}
          rowActions={[
            <RowAction
              key={0}
              tooltipText={siteSelection?.length > 0 ? `View as ${siteSelection[0].text}` : 'Select a Site'}
              icon={CircleCheckIcon}
              onClick={(row) => handleViewAs.bind(this, row)}
              primary
              disabled={!siteSelection?.length > 0}
            />,
            <RowAction key={1} tooltipText="Edit" icon={PencilIcon} onClick={(row) => handleEdit.bind(this, row)} secondary />,
            <RowAction key={2} tooltipText="Delete" icon={WasteIcon} onClick={(row) => handleDelete.bind(this, row)} warning />,
          ]}
        />
      </div>
      <LoadingLine loading={loading} />
      {viewing && iframeUrl !== '' && (
        <div style={{ width: '100%', height: 600 }}>
          <iframe src={iframeUrl} id="embedded-iframe" style={{ width: '100%', height: 600 }} frameBorder="0" allowFullScreen></iframe>
        </div>
      )}
      <Modal onHide={handleHideModal} open={!!editingDashboard} closeOnEsc closeOnOutsideClick>
        <ModalContent>
          <div className={classes.modalContent}>
            <h2>{editingDashboard?._id ? 'Edit Dashboard' : 'Create Dashboard'}</h2>
            {!editingDashboard?._id && (
              <TextInput
                className={classes.textField}
                onChange={updateValue.bind(this, 'dashboardId')}
                value={editingDashboard?.dashboardId || ''}
                type="number"
                labelText="Holistics Dashboard ID"
                required
              />
            )}
            {editingDashboard?._id && (
              <TextInput
                className={classes.textField}
                onChange={updateValue.bind(this, 'name')}
                value={editingDashboard?.name || ''}
                type="text"
                labelText="Dashboard Name"
                required
              />
            )}
            <Select
              placeholderText="Default for Location?"
              onChange={updateValue.bind(this, 'defaultForLocation')}
              selectedItems={editingDashboard?.defaultForLocation || []}
            >
              <SelectOption value="">None</SelectOption>
              <SelectOption value="DASHBOARD_TAB">Dashboard tab</SelectOption>
              <SelectOption value="REPORTS_TAB">Reports tab</SelectOption>
              <SelectOption value="OFFER_VIZ">Offer viz popup</SelectOption>
            </Select>
            <p className={classes.hint}>Should this report show up as the default report on one of the reporting pages?</p>
            <Select
              placeholderText="Report Type"
              onChange={updateValue.bind(this, 'dashboardType')}
              selectedItems={editingDashboard?.dashboardType || []}
            >
              <SelectOption value="CORE">CORE</SelectOption>
              <SelectOption value="CUSTOM">CUSTOM</SelectOption>
            </Select>
            <p className={classes.hint}>If Custom, you&rsquo;ll need to choose which sellers can view it.</p>
            {editingDashboard?.dashboardType?.length > 0 && editingDashboard.dashboardType[0].value === 'CUSTOM' && (
              <Select
                multiple
                search
                placeholderText="Sites with access"
                onChange={updateValue.bind(this, 'sellerSiteIds')}
                selectedItems={editingDashboard?.sellerSiteIds || []}
              >
                {siteSelectOptions}
              </Select>
            )}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button type="button" onClick={handleHideModal} link>
            Cancel
          </Button>
          <Button type="submit" disabled={creating} primary loading={creating} loadingText="Saving..." onClick={saveDashboard}>
            {editingDashboard?._id ? 'Save Changes' : 'Create'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

Dashboard.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
  mutate: PropTypes.func,
};

export default Dashboard;
