import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Button, LoadingSmall, AlertService, Theme as theme } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { fetchWithToken } from '../../services/fetch-with-token';
import AppSettings from '../../app-settings';
import UploadKeheItemExclusionsWidget from './exclusion-widget';
import { Breadcrumbs } from '../../store';

const serverUrl = AppSettings.GRAPHQL_SERVER_URL.replace('admin/', '');
const fullBackup = false;
const checkBackupInterval = 1000 * 60;
const urls = {
  dbStatus: `${serverUrl}backup-restore/backup-status`,
  backupDb: `${serverUrl}backup-restore/backup/${fullBackup}`,
  resetDb: `${serverUrl}data/reset/${AppSettings.DATABASE_RESET_PROFILE}`,
};

const useStyles = createUseStyles({
  backupLoader: {
    display: 'inline-block',
    width: 50,
  },
  backupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backupReady: {
    marginLeft: 20,
  },
  resetWrap: {
    marginTop: 30,
  },
  offerInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  dataUtilitiesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  dataUtility: {
    width: '50%',
    minWidth: 500,
    boxSizing: 'border-box',
    padding: [0, 20, 20, 0],
  },
  dataUtilityInner: {
    padding: 15,
    border: `2px ${theme.grey80} solid`,
    borderRadius: 2,
    height: '100%',
    boxShadow: '0 1px 10px rgba(0,0,0,0.3)',
  },
});

const DataUtilities = ({ match }) => {
  const classes = useStyles();
  const [enableBackup, setEnableBackup] = useState(false);
  const [enableReset, setEnableReset] = useState(true);

  const intervalCheckRef = useRef();

  const checkDbStatus = () => fetchWithToken(urls.dbStatus, {}).then((resp) => setEnableBackup(resp.status === 200));

  useEffect(() => {
    TitleService.setTitles('Data Utilities Panel');
    Breadcrumbs.set([
      {
        url: '/data',
        title: 'Data Utilities Panel',
      },
    ]);
    checkDbStatus();
    intervalCheckRef.current = setInterval(checkDbStatus, checkBackupInterval);
    return () => {
      clearInterval(intervalCheckRef.current);
    };
  }, [match.url]);

  const startBackup = () => {
    fetchWithToken(urls.dbStatus, {}).then((resp) => {
      if (resp.status === 200) {
        fetchWithToken(urls.backupDb, { method: 'POST' });
      }
    });
    setEnableBackup(false);
    clearInterval(intervalCheckRef.current);
    setTimeout(() => {
      intervalCheckRef.current = setInterval(checkDbStatus, checkBackupInterval);
    }, 1000 * 60 * 5);
  };

  const startReset = () => {
    setEnableReset(false);
    fetchWithToken(urls.resetDb, { method: 'POST' })
      .then((resp) => {
        if (resp.status !== 200) throw new Error('Reset failed');
        setEnableReset(true);
        AlertService.alert({ type: 'success', message: 'Database reset was successful!', autoDismiss: true, dismissDelay: 5000 });
      })
      .catch(() => AlertService.alert({ type: 'warning', message: 'Database reset failed.' }));
  };

  return (
    <div>
      <div className={classes.dataUtilitiesGrid}>
        <div className={classes.dataUtility}>
          <div className={classes.dataUtilityInner}>
            <h4>Database Backup</h4>
            <div className={classes.backupContainer}>
              <Button disabled={!enableBackup} onClick={startBackup}>
                Backup Database Now
              </Button>
              {!enableBackup ? (
                <div>
                  <div className={classes.backupLoader}>
                    <LoadingSmall />
                  </div>
                  Currently backing up
                </div>
              ) : (
                <div className={classes.backupReady}>Ready to backup</div>
              )}
            </div>
          </div>
        </div>
        {AppSettings.DATABASE_RESET_PROFILE ? (
          <div className={classes.dataUtility}>
            <div className={classes.dataUtilityInner}>
              <h4>Database Seed</h4>
              <Button onClick={startReset} disabled={!enableReset}>
                Reset Database
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className={classes.dataUtility}>
          <div className={classes.dataUtilityInner}>
            <UploadKeheItemExclusionsWidget classes={classes} />
          </div>
        </div>
      </div>
    </div>
  );
};

DataUtilities.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  mutate: PropTypes.func,
  match: PropTypes.object,
};

export default DataUtilities;
