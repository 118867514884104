/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { UnAuthedRoute, AuthedRoute } from './route-handlers';
import routePaths from './route-paths';

import NotFoundLayout from './layouts/not-found-layout';
import SignIn from './pages/login/sign-in';
import ForgotPassword from './pages/login/forgot-password';
import Logout from './pages/logout';
import ChangePassword from './pages/login/change-password';
import SetResetPassword from './pages/login/set-reset-password';
import RegisterInvitee from './pages/login/register-invitee';
import EditSite from './pages/supplimentation/edit-site';
import Dashboard from './pages/dashboard';
import Sites from './pages/sites';
import { CustomFilters } from './pages/custom-filters';
import UserManagement from './pages/user-management/user-management';
import DataUtilities from './pages/data-utilities';
import { DistributionLists } from './pages/distribution-lists';
import Export from './pages/exports';
import ImportManager from './pages/import-manager';
import ExportManager from './pages/export-manager';
import DataTableManager from './pages/data-table-manager';
import TimebombManager from './pages/timebomb-manager';
import LookerSiteManager from './pages/looker-site-manager';
import { BuyerPortalAccess } from './pages/import-history';
import MarketplaceManager from './pages/marketplace-manager/marketplace-manager';

@withRouter
class RouteSwitch extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location);
    }
  }

  onRouteChanged(location) {
    ReactGA.pageview(`${location.pathname}${location.search}${location.hash}`);
  }

  render() {
    const { user } = this.props;
    return (
      <Switch>
        <UnAuthedRoute exact path={routePaths.signIn} component={SignIn} />
        <UnAuthedRoute exact path={routePaths.forgotPassword} component={ForgotPassword} />
        <UnAuthedRoute exact path={`${routePaths.resetPassword}:token`} component={SetResetPassword} />
        <AuthedRoute exact path={routePaths.home} component={Dashboard} user={user} />
        <AuthedRoute exact path={routePaths.changePassword} component={ChangePassword} user={user} />
        <UnAuthedRoute exact path={`${routePaths.registerInvitee}:token`} component={RegisterInvitee} />
        <AuthedRoute exact path={routePaths.userManagement} component={UserManagement} user={user} />
        <AuthedRoute exact path={routePaths.editSite} component={EditSite} user={user} />
        <AuthedRoute exact path={routePaths.data} component={DataUtilities} user={user} />
        <AuthedRoute exact path={routePaths.logout} component={Logout} user={user} />
        <AuthedRoute exact path={routePaths.sites} component={Sites} user={user} />
        <AuthedRoute exact path={routePaths.customFilters} component={CustomFilters} user={user} />
        <AuthedRoute exact path={`${routePaths.distributionLists}:siteId`} component={DistributionLists} user={user} />
        <AuthedRoute exact path={routePaths.exports} component={Export} user={user} />
        <AuthedRoute exact path={routePaths.importManager} component={ImportManager} user={user} />
        <AuthedRoute exact path={routePaths.exportManager} component={ExportManager} user={user} />
        <AuthedRoute exact path={routePaths.lookerSiteManager} component={LookerSiteManager} user={user} />
        <AuthedRoute exact path={routePaths.dataTableManager} component={DataTableManager} user={user} />
        <AuthedRoute exact path={routePaths.timeBombManager} component={TimebombManager} user={user} />
        <AuthedRoute path={routePaths.marketplaceManager} component={MarketplaceManager} user={user} />
        <AuthedRoute exact path={routePaths.buyerPortalAccess} component={BuyerPortalAccess} user={user} />
        <Route component={NotFoundLayout} />
      </Switch>
    );
  }
}

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <RouteSwitch user={this.props.user} />
      </Router>
    );
  }
}

Routes.propTypes = {
  user: PropTypes.object,
};
