import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import injectSheet from 'react-jss';
import { Select, SelectOption, ButtonBar, Button, Theme as theme } from '@spoiler-alert/ui-library';
import { useQuery } from '@apollo/client';
import { CUSTOMERS_AND_EXPORT_PROFILES } from './export-queries';

const styles = {
  controls: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  siteSelectWrap: {
    borderRadius: '4px',
    border: `1px ${theme.grey30} solid`,
    marginRight: 8,
    width: 260,
    paddingRight: 8,
    boxSizing: 'content-box',
  },
  siteSelectOption: {
    paddingRight: '10px !important',
  },
  siteListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 25,
  },
};

const ExportManagerNavigation = ({ classes, site, setSite, setExportProfile, exportType, setExportType, setSheetWriters }) => {
  const { data, error } = useQuery(CUSTOMERS_AND_EXPORT_PROFILES);
  const sites = data?.exportManagerQuery?.allSellerOrgs;
  const [siteSelection, setSiteSelection] = useState(null);
  const currentSite = sites && sites.find((s) => s._id === site._id);
  const sheetWriters = data?.exportManagerQuery?.possibleSheetWriters;

  const changeSite = (option) => {
    setSiteSelection(option);
    const selectedSite = sites.find((s) => s._id === option[0].value);
    setSite({ _id: selectedSite._id, siteName: selectedSite.siteName });
  };

  useEffect(() => {
    if (currentSite?.exportProfiles?.length > 0) {
      const profile = currentSite.exportProfiles.find((ep) => ep.exportType === exportType);
      if (profile) {
        setExportProfile(profile);
      } else {
        setExportProfile(null);
      }
    } else {
      setExportProfile(null);
    }
  }, [currentSite?._id, exportType]);

  useEffect(() => {
    if (sheetWriters) setSheetWriters(sheetWriters);
  });

  const changeExportProfile = (et) => {
    setExportType(et);
    const profile = currentSite?.exportProfiles?.find((ep) => ep.exportType === et);
    if (profile) {
      setExportProfile(profile);
    } else {
      setExportProfile(null);
    }
  };

  return (
    <div>
      <div className={classes.controls}>
        <div className={classes.siteSelectWrap}>
          <Select minimal search placeholderText="Select a Site" onChange={changeSite} selectedItems={siteSelection}>
            {sites &&
              sites.map((s) => (
                <SelectOption key={s._id} value={s._id} searchText={s.siteName} className={classes.siteSelectOption}>
                  <div className={classes.siteListItem}>{s.siteName}</div>
                </SelectOption>
              ))}
          </Select>
        </div>
        <div className={classes.buttonRow}>
          {currentSite && (
            <ButtonBar>
              <Button onClick={() => changeExportProfile('OFFER SHEET')} toggle active={exportType === 'OFFER SHEET'} key="offer">
                Offer Sheet
              </Button>
              <Button onClick={() => changeExportProfile('AWARD SHEET')} toggle active={exportType === 'AWARD SHEET'} key="award">
                Award Sheet
              </Button>
            </ButtonBar>
          )}
        </div>
      </div>
      {error && error.message}
    </div>
  );
};

ExportManagerNavigation.propTypes = {
  classes: PropTypes.object,
  site: PropTypes.object,
  setSite: PropTypes.func,
  setExportProfile: PropTypes.func,
  exportType: PropTypes.string,
  setExportType: PropTypes.func,
  setSheetWriters: PropTypes.func,
};

export default injectSheet(styles)(ExportManagerNavigation);
