import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TextInput, LoadingLarge, Button, NoImageIcon, AlertService, Checkbox } from '@spoiler-alert/ui-library';
import { useMutation } from '@apollo/client';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { SaveStorefrontMutation } from './marketplace-mutations';
import marketplaceStyles from './marketplace-styles';
import HTMLEditor from '../../components/editor/html-editor';

const useStyles = createUseStyles(marketplaceStyles);

const StorefrontDetails = ({ bread, match, history, storefronts, loadingStorefronts }) => {
  const classes = useStyles();
  const dbStorefront = storefronts?.find((s) => s._id === match.params.storefrontId);
  const [storefront, setStorefront] = useState(null);
  const [changedFields, setChangedFields] = useState([]);

  const [save] = useMutation(SaveStorefrontMutation);

  const breadcrumbs = [
    ...bread,
    {
      url: history.location.pathname,
      title: dbStorefront?.siteName,
    },
  ];

  useEffect(() => {
    if (dbStorefront) {
      TitleService.setTitles(`Marketplace Manager => ${dbStorefront?.siteName}`);
      Breadcrumbs.set(breadcrumbs);
      setStorefront({ ...dbStorefront });
    }
  }, [dbStorefront]);

  const updateValue = (field, value) => {
    const _storefront = { ...storefront };
    _storefront[field] = value;
    setChangedFields([...new Set([...changedFields, field]).values()]);
    setStorefront(_storefront);
  };

  const updateArrayValue = (field, value) => {
    updateValue(
      field,
      value.split(',').map((v) => v.trim())
    );
  };

  const updateCheckboxValue = (field, value) => {
    const _storefront = { ...storefront };
    _storefront[field] = value.checked;
    setChangedFields([...new Set([...changedFields, field]).values()]);
    setStorefront(_storefront);
  };

  const saveStorefront = () => {
    const variables = {
      _id: dbStorefront._id,
      fields: {
        hasPackout: storefront.hasPackout,
      },
    };
    changedFields.forEach((f) => {
      variables.fields[f] = storefront[f];
    });
    save({
      variables,
    })
      .then(() => history.push(routePaths.marketplaceManager))
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };

  if (loadingStorefronts || storefront === null) return <LoadingLarge />;

  return (
    <div>
      <h3>{storefront.siteName}</h3>
      <div className={classes.fieldBox}>
        <div className={classes.fieldLabel}>Facilities:</div>
        <ul>
          {storefront.facilities.map((site) => (
            <li key={site._id}>{site.siteName}</li>
          ))}
        </ul>
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'tagline')}
          value={storefront.tagline || ''}
          type="text"
          labelText="Tagline"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'description')}
          value={storefront.description || ''}
          type="text"
          labelText="Short Description"
          required
        />
      </div>
      <div className={classes.textAreaBox}>
        <div className={classes.fieldLabel}>Long Description</div>
        <HTMLEditor defaultValue={storefront.longDescription} onChange={updateValue.bind(this, 'longDescription')} />
      </div>
      <div className={classes.imageBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'logo')}
          value={storefront.logo || ''}
          type="text"
          labelText="Logo URL"
          required
        />
        {storefront.logo ? <img src={storefront.logo} /> : <NoImageIcon className={classes.noImage} />}
      </div>
      <div className={classes.imageBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'splashLogo')}
          value={storefront.splashLogo || ''}
          type="text"
          labelText="Splash Logo URL"
          required
        />
        {storefront.splashLogo ? <img src={storefront.splashLogo} /> : <NoImageIcon className={classes.noImage} />}
      </div>
      <div className={classes.imageBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'emailBanner')}
          value={storefront.emailBanner || ''}
          type="text"
          labelText="Email Banner URL"
          required
        />
        {storefront.emailBanner ? <img src={storefront.emailBanner} /> : <NoImageIcon className={classes.noImage} />}
      </div>
      <div className={classes.imageBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'landingPageThumbnail')}
          value={storefront.landingPageThumbnail || ''}
          type="text"
          labelText="Landing Page Thumbnail URL"
          required
        />
        {storefront.landingPageThumbnail ? <img src={storefront.landingPageThumbnail} /> : <NoImageIcon className={classes.noImage} />}
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateValue.bind(this, 'website')}
          value={storefront.website || ''}
          type="url"
          labelText="Website URL"
          required
        />
        Click to test:{' '}
        <a target="_blank" rel="noreferrer" href={storefront.website}>
          {storefront.website}
        </a>
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateArrayValue.bind(this, 'retailPresence')}
          value={storefront.retailPresence.join(', ')}
          type="text"
          labelText="Retail Presence"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <TextInput
          className={classes.textField}
          onChange={updateArrayValue.bind(this, 'categories')}
          value={storefront.categories.join(', ')}
          type="text"
          labelText="Categories"
          required
        />
      </div>
      <div className={classes.fieldBox}>
        <div className={classes.fieldLabel}>Has Packout?</div>
        <Checkbox className={classes.textField} onChecked={updateCheckboxValue.bind(this, 'hasPackout')} checked={storefront.hasPackout} required />
      </div>
      <div className={classes.submitBox}>
        <Button primary onClick={saveStorefront}>
          Save Storefront
        </Button>
        <Button secondary onClick={() => history.push(routePaths.marketplaceManager)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

StorefrontDetails.propTypes = {
  bread: PropTypes.array,
  match: PropTypes.object,
  history: PropTypes.object,
  loadingStorefronts: PropTypes.bool,
  storefronts: PropTypes.array,
};

export default StorefrontDetails;
