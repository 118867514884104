import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Select, SelectOption, Row, Checkbox, Col, TrashIcon, TextBox, Button, ColumnFormatters } from '@spoiler-alert/ui-library';
import { listToOptions } from '../etl-helpers';
import RowOrderer from '../export-manager/row-orderer';
import ETLImportErrors from '../import-manager/etl-import-errors';

const styles = {
  textBoxWrap: {
    '&>div:last-child': {
      height: 32,
    },
    display: 'flex',
    alignItems: 'center',
  },
  trashColumn: {
    width: 75,
  },
  trashButton: {
    margin: [-7, 0, -10, 0],
  },
  fieldSelect: {},
  selectColumn: {
    '&>span': {
      display: 'flex',
      alignItems: 'center',
      '&>$fieldSelect': {
        flexGrow: 1,
        '&>div': {
          width: '100%',
          '&>div>span': {
            paddingLeft: '0 !important',
          },
        },
      },
    },
  },
  checkbox: {
    margin: 'auto',
    width: '18px',
    height: '18px',
    display: 'block',
  },
  checkboxColumn: {
    width: '135px',
    paddingRight: '40px !important',
  },
};

const sortDirections = ['asc', 'desc'];

const DataTableColumn = ({
  classes,
  column,
  possibleFields,
  onUp,
  onDown,
  position,
  first,
  last,
  updateColumn,
  deleteColumn,
  clearFormerSortPreferences,
  tableType,
}) => {
  function toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  const possibleFormatters = useMemo(() => {
    const field = possibleFields.find((f) => f.name === column.field);
    if (!field || !tableType) {
      return [];
    }
    const fieldType = field.type;
    const tableTypeCamel = toCamelCase(tableType);
    if (!fieldType || !tableTypeCamel) {
      return [];
    }
    const fieldObj = ColumnFormatters.generic[fieldType] || {};
    const tableTypeObj = ColumnFormatters[tableTypeCamel] || {};
    const genericObj = ColumnFormatters.generic.access || {};
    return [...Object.keys(fieldObj), ...Object.keys(tableTypeObj), ...Object.keys(genericObj)];
  }, [column.field, tableType]);

  const setFieldValue = (field, value) => {
    const newColumn = { ...column };
    newColumn[field] = value;
    updateColumn(newColumn, position);
  };

  const setDropdownValue = (field, value) => {
    setFieldValue(field, value.length > 0 ? value[0].value : null);
  };

  const changeDefaultSort = (value) => {
    clearFormerSortPreferences(position, value.checked);
  };

  const formatSortDirectionOptions = () =>
    sortDirections.map((sd) => (
      <SelectOption key={sd} value={sd}>
        {sd}
      </SelectOption>
    ));

  const changeField = (value) => {
    const newColumn = { ...column };
    newColumn.field = value.length > 0 ? value[0].value : null;
    newColumn.formatter = null;
    updateColumn(newColumn, position);
  };

  const internalFields = possibleFields.map((f) => f.name);

  const displayNameError = column.displayName ? null : 'Label is required';
  const fieldError = column.field ? null : 'Internal Name is required';

  const columnErrors = [displayNameError, fieldError].filter(Boolean);

  return (
    <Row style={{ backgroundColor: columnErrors.length > 0 ? '#fdf0f0' : '#ffffff' }}>
      <Col>
        <RowOrderer onUp={onUp} onDown={onDown} position={position} first={first} last={last} />
      </Col>
      <Col>
        <div className={classes.textBoxWrap}>
          <ETLImportErrors errors={columnErrors} />
          <TextBox
            style={{ width: 200, textAlign: 'left', paddingLeft: 8 }}
            value={column.displayName || ''}
            onChange={(value) => setFieldValue('displayName', value)}
          />
        </div>
      </Col>
      <Col>
        <Select
          minimal
          search
          disabled={column.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={{
            value: column.field,
            text: column.field,
          }}
          onChange={(value) => changeField(value)}
          displayAll={true}
        >
          {listToOptions(internalFields)}
        </Select>
      </Col>
      <Col>
        <Select
          minimal
          search
          disabled={column.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={{ value: column.formatter, text: column.formatter }}
          onChange={(value) => setDropdownValue('formatter', value)}
          manualInput={true}
        >
          {listToOptions(possibleFormatters)}
        </Select>
      </Col>
      <Col className={classes.checkboxColumn}>
        <Checkbox className={classes.checkbox} checked={column.defaultSort || false} onChecked={(value) => changeDefaultSort(value)} />
      </Col>
      <Col>
        <Select
          minimal
          search
          disabled={!column.defaultSort}
          containerClassName={classes.fieldSelect}
          selectedItem={{ value: column.defaultSortDirection, text: column.defaultSortDirection }}
          onChange={(value) => setDropdownValue('defaultSortDirection', value)}
        >
          {formatSortDirectionOptions()}
        </Select>
      </Col>
      <Col className={classes.trashColumn}>
        <Button
          warning
          resting={true}
          icon={TrashIcon}
          className={classes.trashButton}
          disabled={column.locked}
          onClick={(e) => {
            e.preventDefault();
            deleteColumn(position);
          }}
        />
      </Col>
    </Row>
  );
};

DataTableColumn.propTypes = {
  classes: PropTypes.object,
  column: PropTypes.object,
  possibleFields: PropTypes.array,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  updateColumn: PropTypes.func,
  deleteColumn: PropTypes.func,
  clearFormerSortPreferences: PropTypes.func,
  tableType: PropTypes.string,
};

export default injectSheet(styles)(DataTableColumn);
