import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Select, Row, Col, TrashIcon, TextBox, EmbiggeningTextBox, Button, Theme, ColorPicker } from '@spoiler-alert/ui-library';
import { listToOptions, cleanModel, SAVE_DELAY } from '../etl-helpers';
import RowOrderer from './row-orderer';

const styles = {
  textBoxWrap: {
    '&>div': {
      height: 32,
    },
  },
  smallTextBox: {
    width: '20%',
    '&>div': {
      height: 32,
    },
  },
  trashColumn: {
    width: 75,
  },
  mergesColumn: {
    width: '100%',
  },
  trashButton: {
    margin: [-7, 0, -10, 0],
  },
  fieldSelect: {},
  textBoxColumn: {
    width: '20%',
  },
  selectColumn: {
    width: '15%',
    '&>span': {
      display: 'flex',
      alignItems: 'center',
      '&>$fieldSelect': {
        flexGrow: 1,
        '&>div': {
          width: '100%',
          '&>div>span': {
            paddingLeft: '0 !important',
          },
        },
      },
    },
  },
  justSaved: {
    boxShadow: `inset 4px 0 0 ${Theme.green30}`,
  },
  colorPickerContainer: {
    display: 'flex',
  },
  truncatedTextbox: {
    width: '100%',
    maxWidth: '446px',
    paddingLeft: '8px',
  },
  embiggenedTextbox: {
    left: '0px',
  },
};

const MultilineHeader = ({
  classes,
  multilineHeader,
  possibleColors,
  deleteMultilineHeader,
  updateMultilineHeader,
  onUp,
  onDown,
  position,
  first,
  last,
  justSaved,
}) => {
  const [editingMultilineHeader, setEditingMultilineHeader] = useState(() => cleanModel(multilineHeader));

  const timerRef = useRef(null);
  const [selectedHeaderColor, setSelecteHeaderColor] = useState(editingMultilineHeader.headerStyles.color);
  const [selectedColumnColor, setSelectedColumnColor] = useState(editingMultilineHeader.columnStyles.color);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  useEffect(() => {
    setEditingMultilineHeader(cleanModel(multilineHeader));
  }, [multilineHeader]);

  const restartTimer = (recordToSave) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      updateMultilineHeader(recordToSave);
    }, SAVE_DELAY);
  };

  const setFieldValue = (field, value) => {
    const newMultilineHeader = { ...editingMultilineHeader };
    newMultilineHeader[field] = value;
    setEditingMultilineHeader(newMultilineHeader);
    restartTimer(newMultilineHeader);
  };

  const setDropdownValue = (field, value) => {
    if (value.length > 0 && value[0].value === 'Input') {
      setSelecteHeaderColor('Bumblebee');
      setSelectedColumnColor('Snow');
    }
    if (value.length > 0 && value[0].value === 'Info') {
      setSelecteHeaderColor('Snow');
      setSelectedColumnColor(undefined);
    }
    setFieldValue(field, value.length > 0 ? value[0].value : null);
  };

  return (
    <Row className={justSaved ? classes.justSaved : ''}>
      <Col>
        <RowOrderer onUp={onUp} onDown={onDown} position={position} first={first} last={last} />
      </Col>
      <Col className={classes.textBoxColumn}>
        <div className={classes.textBoxWrap} data-testid="textbox">
          {editingMultilineHeader.headerType === 'Info' ? (
            <EmbiggeningTextBox
              truncatedClass={classes.truncatedTextbox}
              embiggenedClass={classes.embiggenedTextbox}
              value={editingMultilineHeader.label || ''}
              onChange={(value) => setFieldValue('label', value)}
            />
          ) : (
            <TextBox
              style={{ width: '100%', textAlign: 'left', paddingLeft: 8 }}
              value={editingMultilineHeader.label || ''}
              onChange={(value) => setFieldValue('label', value)}
            />
          )}
        </div>
      </Col>
      <Col className={classes.selectColumn}>
        <Select
          minimal
          disabled={editingMultilineHeader.locked}
          containerClassName={classes.fieldSelect}
          selectedItem={
            editingMultilineHeader.headerType
              ? { value: editingMultilineHeader.headerType, text: editingMultilineHeader.headerType }
              : { value: 'Input', text: 'Input' }
          }
          onChange={(value) => setDropdownValue('headerType', value)}
        >
          {listToOptions(['Input', 'Info'])}
        </Select>
      </Col>
      <Col className={classes.mergesColumn}>
        <div className={classes.smallTextBox} data-testid="textbox">
          <TextBox
            style={{ width: 57, textAlign: 'right', paddingRight: 8 }}
            value={editingMultilineHeader.merges || ''}
            onChange={(value) => setFieldValue('merges', parseFloat(value))}
          />
        </div>
      </Col>
      <Col>
        {editingMultilineHeader.headerType === 'Info' ? (
          <div className={classes.colorPickerContainer}>
            <ColorPicker
              label={'H'}
              title={'Header Color'}
              colors={possibleColors}
              selectedColor={selectedHeaderColor}
              onSelect={(value) => setFieldValue('headerStyles', { color: value })}
            />
          </div>
        ) : (
          <div className={classes.colorPickerContainer}>
            <ColorPicker
              label={'H'}
              title={'Header Color'}
              colors={possibleColors}
              selectedColor={selectedHeaderColor}
              onSelect={(value) => setFieldValue('headerStyles', { color: value })}
            />
            <ColorPicker
              label={'C'}
              title={'Cell Color'}
              colors={possibleColors}
              selectedColor={selectedColumnColor}
              onSelect={(value) => setFieldValue('columnStyles', { color: value })}
            />
          </div>
        )}
      </Col>
      <Col className={classes.trashColumn}>
        <Button
          warning
          resting={true}
          icon={TrashIcon}
          className={classes.trashButton}
          disabled={editingMultilineHeader.locked}
          onClick={(e) => {
            e.preventDefault();
            deleteMultilineHeader(editingMultilineHeader);
          }}
        />
      </Col>
    </Row>
  );
};

MultilineHeader.propTypes = {
  classes: PropTypes.object,
  multilineHeader: PropTypes.object,
  possibleColors: PropTypes.object,
  deleteMultilineHeader: PropTypes.func,
  updateMultilineHeader: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
  justSaved: PropTypes.bool,
};

export default injectSheet(styles)(MultilineHeader);
