import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Navigation, NavigationItem, Sidebar, Logo, Theme } from '@spoiler-alert/ui-library';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import routePaths from '../../route-paths';
import Actions from './actions';
import AppSettings from '../../app-settings';

const styles = {
  sa__logo: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: 152,
    height: 33,
    margin: '29px 0 35px 24px',
    padding: 0,
  },
  sa__img: {
    width: 152,
    height: 33,
  },
  '@media (max-width: 840px)': {
    sa__logo: {
      display: 'none',
    },
  },
};

@injectSheet(styles)
@withRouter
@inject('rootStore')
@observer
export default class SidebarWrapper extends Component {
  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
  }

  close = () => {};

  render() {
    const { classes, onClose, onOpen, rootStore } = this.props;

    return (
      <Sidebar
        collapsed={rootStore.sidebarStatus.collapsed}
        onClose={onClose}
        onOpen={onOpen}
        backgroundColor={
          (AppSettings.ENVIRONMENT_NAME === 'staging' && Theme.teal30) ||
          undefined ||
          (AppSettings.ENVIRONMENT_NAME === 'uat' && Theme.yellow30) ||
          undefined
        }
      >
        <Logo route="/" className={classes.sa__logo} imageClassName={classes.sa__img} />
        <Navigation>
          <NavigationItem defaultText="Dashboards" id="dashboard" i18nKey="dashboard" route={routePaths.home} icon="dashboard" onClick={this.close} />
          <NavigationItem
            defaultText="Site Administration"
            id="sites"
            i18nKey="sites"
            route={routePaths.sites}
            icon="building"
            onClick={this.close}
          />
          <NavigationItem defaultText="Site Editation" id="edit-site" i18nKey="edit" route={routePaths.editSite} icon="wand" onClick={this.close} />
          <NavigationItem
            defaultText="Import Manager"
            id="import-manager"
            i18nKey="import-manager"
            route={routePaths.importManager}
            icon="importmanager"
            onClick={this.close}
          />
          <NavigationItem
            defaultText="Export Manager"
            id="export-manager"
            i18nKey="export-manager"
            route={routePaths.exportManager}
            icon="exportmanager"
            onClick={this.close}
          />
          <NavigationItem
            defaultText="Data Table Manager"
            id="data-table-manager"
            i18nKey="data-table-manager"
            route={routePaths.dataTableManager}
            icon="datatablemanager"
            onClick={this.close}
          />
          <NavigationItem
            defaultText="Looker Site Manager"
            id="looker-site-manager"
            i18nKey="looker-site-manager"
            route={routePaths.lookerSiteManager}
            icon="usergroup"
            onClick={this.close}
          />
          <NavigationItem defaultText="Data" id="data" i18nKey="data" route={routePaths.data} icon="boxhandle" onClick={this.close} />
          <NavigationItem
            defaultText="Custom Filters"
            id="customFilters"
            i18nKey="customFilters"
            route={routePaths.customFilters}
            icon="filter"
            onClick={this.close}
          />
          <NavigationItem defaultText="Exports" id="exports" i18nKey="exports" route={routePaths.exports} icon="export" onClick={this.close} />
          <NavigationItem
            defaultText="Timebomb Mgr"
            id="timebomb-manager"
            i18nKey="timebomb-manager"
            route={routePaths.timeBombManager}
            icon="history"
            onClick={this.close}
          />
          <NavigationItem
            defaultText="Marketplace Mgr"
            id="marketplace-manager"
            i18nKey="marketplace-manager"
            route={routePaths.marketplaceManager}
            icon="building"
            onClick={this.close}
          />
        </Navigation>
        <Actions>{this.props.children}</Actions>
      </Sidebar>
    );
  }
}

SidebarWrapper.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  history: PropTypes.object,
  rootStore: PropTypes.object,
  children: PropTypes.node,
};
